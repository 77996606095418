import { consentsActions } from '@adeptlms/lingu-students-react-shared';
import { Translate } from 'i18n';
import { useState } from 'react';
import { useTypedDispatch, useTypedSelector } from 'students/stores/_utils';
import { userSelectors } from 'students/stores/user';
import { AcceptButtonsBlock } from 'students/views/shared/bundles/assignments/components/Messenger/components';
import AvatarImage from 'students/views/shared/components/AvatarImage';
import CheckboxItem from 'students/views/shared/components/checkbox/CheckboxItem';
import { customMediaQuery } from 'students/views/shared/styled';
import styled from 'styled-components';
import aiAvatar from './assets/ai_avatar.png';

type tInitialScreen = {
  goNext: () => void;
  skipChat: () => void;
  botName?: string;
  botAvatar?: string;
  serviceProvider?: string;
};

const InitialScreen = ({
  goNext,
  skipChat,
  botName,
  botAvatar,
  serviceProvider
}: tInitialScreen): JSX.Element => {
  const profile = useTypedSelector(userSelectors.selectProfile);
  const [researchConsent, setResearchConsent] = useState<boolean>(
    profile?.consents.research || false
  );
  const dispatch = useTypedDispatch();

  const handleOnResearchConsentClick = async () => {
    setResearchConsent((prev) => !prev);
    await dispatch(
      consentsActions.sendResearchConsent({ consentGiven: !researchConsent })
    );
  };

  return (
    <SWrapper>
      <SAvatar src={botAvatar || aiAvatar} alt="" />

      <SHeading>
        <Translate
          str="frontend.member.assignments.chat_with"
          params={{ name: botName || 'Bot' }}
        />
      </SHeading>

      <SInstructions>
        <Translate
          str="frontend.member.assignments.chat_bot_disclaimer"
          params={{
            botName: botName || 'Bot',
            serviceProvider: serviceProvider || 'OpenAI'
          }}
        />
      </SInstructions>

      {profile?.consents.research === null ? (
        <SAgreeCheckboxBlock>
          {profile?.consents.research === undefined ||
          profile?.consents.research === null ? (
            <SCheckboxItem
              option={{
                title: (
                  <div>
                    <Translate str="frontend.auth.i_allow_to_use_my_data" />{' '}
                    <SPrivacyPolicyLink
                      target="_blank"
                      href="https://lingu.no/personvern"
                      rel="noreferrer"
                    >
                      <Translate str="frontend.course.privacy_policy" />
                    </SPrivacyPolicyLink>{' '}
                    <Translate str="frontend.auth.for_details" />
                  </div>
                ),
                value: '1'
              }}
              checked={researchConsent}
              onClick={handleOnResearchConsentClick}
            />
          ) : null}
        </SAgreeCheckboxBlock>
      ) : null}

      <SAcceptButtonsBlock
        onSkip={skipChat}
        onAccept={goNext}
        skipText={<Translate str="frontend.member.assignments.skip_chat" />}
        acceptText={<Translate str="frontend.member.assignments.start_chat" />}
      />

      <SDisclaimer>
        <Translate str="frontend.member.assignments.ai_disclaimer" />
      </SDisclaimer>
    </SWrapper>
  );
};

export default InitialScreen;

const SWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  align-self: center;
  background: var(--assignments-card-background-mobile);
  box-shadow: var(--assignments-card-shadow);
  border-top-left-radius: 40px;
  border-top-right-radius: 40px;
  margin-block-end: -1.25rem;
  margin-inline-start: -14px;
  margin-inline-end: -14px;
  padding: 28px 12px;
  position: relative;
  flex-grow: 1;

  ${customMediaQuery('tablet')} {
    background: var(--assignments-card-background);
    margin: 0;
    width: 100%;
    padding: 68px 56px 36px;
    border-bottom-left-radius: 40px;
    border-bottom-right-radius: 40px;
    flex-grow: initial;
    max-width: 720px;
  }
`;

const SAvatar = styled(AvatarImage)`
  vertical-align: middle;
  height: 7.5rem;
  width: 7.5rem;
  margin-block-end: 16px;

  ${customMediaQuery('tablet')} {
    margin-block-end: 12px;
  }
`;

const SHeading = styled.h2`
  font-size: 1.375rem;
  line-height: 1.75rem;
  font-weight: 700;
  margin-block-end: 12px;
  text-align: center;

  ${customMediaQuery('tablet')} {
    font-size: 1.625rem;
    line-height: 2rem;
    width: 85%;
  }
`;

const SInstructions = styled.p`
  font-size: 0.9375rem;
  line-height: 1.125rem;
  margin-block-end: 16px;
  font-weight: 400;
  text-align: center;

  ${customMediaQuery('tablet')} {
    font-size: 1.125rem;
    line-height: 1.5rem;
    margin-block-end: 28px;
    width: 85%;
  }
`;

const SAgreeCheckboxBlock = styled.div`
  padding: 20px;
  background: #ffffff;
  border-radius: 20px;
  margin: 0 0 12px;
  width: 100%;
  display: flex;
  gap: 10px;
  flex-direction: column;

  label {
    color: var(--heading-text-color);
    font-family: var(--lingu-text-font-family);
    align-items: flex-start;
    font-size: 1rem;
    line-height: 1.3rem;
    align-self: flex-start;
  }

  div {
    background-color: var(--settings-checkbox-background);
    border: 2px solid transparent;
    min-width: 24px;
  }

  svg {
    fill: #ffffff;
  }
`;

const SCheckboxItem = styled(CheckboxItem)`
  margin: 0;

  & > .checkbox-item-label {
    color: var(--paragraph-text-color);
    line-height: 22px;
  }

  & .checkbox_item-checkbox {
    border-color: var(--sub-text-color);
    margin-inline-end: 8px;
    margin-block-start: 2px;
  }
`;

const SAcceptButtonsBlock = styled(AcceptButtonsBlock)`
  margin-block-end: 12px;

  & button {
    border: none;
  }
`;

const SDisclaimer = styled.span`
  opacity: 0.6;
  font-size: 0.875rem;
  line-height: 1rem;
  text-align: center;
`;

const SPrivacyPolicyLink = styled.a`
  color: var(--color-black);
  text-decoration: underline;

  &:hover,
  &:active,
  &:focus {
    color: var(--color-black);
  }
`;
