import {
  IAssignmentTask,
  IAssignmentTaskItemSession,
  IAssignmentTaskSession,
  useAssignmentTask
} from '@adeptlms/lingu-students-react-shared';
import { Translate } from 'i18n';
import { FC, useEffect, useRef, useState } from 'react';
import {
  AssignmentAILicenseSelector,
  AssignmentNoLicenseToAccept,
  AssignmentTag,
  Messenger
} from 'students/views/shared/bundles/assignments';
import { NextButton } from 'students/views/shared/components/Buttons';
import UrlIcon from 'students/views/shared/components/UrlIcon';
import { usePromptContext } from 'students/views/shared/hooks';
import { customMediaQuery } from 'students/views/shared/styled';
import styled from 'styled-components';

import { tMessengerRef } from 'students/views/shared/bundles/assignments/components/Messenger/Messenger';
import { AcceptButtonsBlock } from 'students/views/shared/bundles/assignments/components/Messenger/components';
import { ICheckTaskProps } from '../types';
import IncomingAIMessage from './components/IncomingAIMessage';
import ErrorIcon from './components/assets/error_icon.svg';
import InitialScreen from './components/InitialScreen';

const CheckAssignmentAITask: FC<
  ICheckTaskProps<IAssignmentTask, IAssignmentTaskSession>
> = ({ task, isCompleting, taskSession, onNext }) => {
  const [showInitialScreen, setShowInitialScreen] = useState<boolean>(true);
  const [showNextButton, setShowNextButton] = useState(false);
  const messengerRef = useRef<tMessengerRef>(null);

  const {
    assignmentData,
    status,
    actions,
    canSubmit,
    canAcceptAssignmentTask,
    licensesCorrectionInfo,
    canChangeLicense,
    studentCanAddNewAnswer,
    lastStudentMessage
  } = useAssignmentTask({ task });

  const { fetchAssignment } = actions;

  const { makeDirty } = usePromptContext();

  useEffect(() => {
    if (status === 'initial') {
      fetchAssignment();
      makeDirty(false);
    }
  }, [fetchAssignment, status, makeDirty]);

  const handleSkip = () => {
    onNext(
      taskSession?.taskItemSessions.map((item: IAssignmentTaskItemSession) => ({
        ...item,
        skipped: true
      })) || []
    );
  };

  const renderNextButtonBlock = () => (
    <SNextButton
      size="90px"
      className="ln-m-0"
      onClick={handleSkip}
      disabled={isCompleting}
      iconColor="#00A5D7"
    />
  );

  const goToChat = () => {
    setShowInitialScreen(false);
  };

  if (!assignmentData || showInitialScreen === undefined) {
    return null;
  }

  const showLicenseSelector =
    licensesCorrectionInfo?.currentLicense &&
    canChangeLicense &&
    licensesCorrectionInfo?.changeCurrentLicense;

  const hasStudentAnswer = lastStudentMessage !== null;

  const showFooterBlock =
    showNextButton || assignmentData?.assignmentId || status === 'submit_failed';

  function renderMainBlock() {
    if (showInitialScreen && !hasStudentAnswer && canAcceptAssignmentTask) {
      return (
        <InitialScreen
          goNext={goToChat}
          skipChat={handleSkip}
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          botName={task.botname}
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          botAvatar={task.botAvatarURL}
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          serviceProvider={task.serviceProvider}
        />
      );
    } else if (assignmentData) {
      return (
        <Messenger
          assignment={assignmentData}
          actions={actions}
          status={status}
          submitDisabled={!canSubmit}
          ref={messengerRef}
          studentCanAddNewAnswer={studentCanAddNewAnswer}
          lastStudentMessage={lastStudentMessage}
          initialShowReplyBlock={!lastStudentMessage && !showInitialScreen}
          onReplyActivityChange={(active) => {
            if (!active && !hasStudentAnswer) {
              setShowInitialScreen(true);
              setShowNextButton(false);
            }
          }}
          fallbackMessageRender={(message) =>
            message.from === 'incoming:ai' ? (
              <IncomingAIMessage message={message} collapseWithNext={false} />
            ) : null
          }
          footer={
            showFooterBlock ? (
              renderNextButtonBlock()
            ) : showInitialScreen ? (
              <SAcceptButtonsBlock
                onSkip={handleSkip}
                onAccept={() => {
                  goToChat();
                  messengerRef.current?.openReply();
                }}
                skipText={<Translate str="frontend.member.assignments.skip_chat" />}
                acceptText={<Translate str="frontend.member.assignments.start_chat" />}
              />
            ) : null
          }
          textReplyError={
            status === 'submit_failed' ? (
              <SReplyError>
                <SUrlIcon url={ErrorIcon} height="22px" width="22px" />

                <p>
                  <Translate str="frontend.member.assignments.error_encountered_during_generation" />
                </p>
              </SReplyError>
            ) : null
          }
          variant="light"
        />
      );
    }
    return null;
  }

  return (
    <STaskWrapper>
      <SAssignmentTag type={assignmentData.inputType} />

      {canChangeLicense && showLicenseSelector ? (
        <AssignmentAILicenseSelector
          // @ts-expect-error
          licensesSubmitFor={task.reviewers}
          currentLicense={licensesCorrectionInfo.currentLicense}
          changeCurrentLicense={licensesCorrectionInfo.changeCurrentLicense}
        />
      ) : null}

      {!canAcceptAssignmentTask ? (
        <>
          <AssignmentNoLicenseToAccept />

          {renderNextButtonBlock()}
        </>
      ) : (
        renderMainBlock()
      )}
    </STaskWrapper>
  );
};

export default CheckAssignmentAITask;

const STaskWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;

  ${customMediaQuery('tablet')} {
    flex-grow: 1;
    margin: 0 0 30px;
    align-items: center;
    align-self: center;
    max-width: 664px;
  }
`;
const SNextButton = styled(NextButton)`
  margin: 80px auto 10px !important;

  ${customMediaQuery('tablet')} {
    margin: 40px auto -60px !important;
  }
`;

const SAssignmentTag = styled(AssignmentTag)`
  width: max-content;
  margin: 0 auto 28px;
`;

const SReplyError = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: 12px;
  background: #ffffff;
  margin-block-start:  -1rem;
  margin-inline-end: -3rem;
  margin-inline-start: e-start: -1rem;
  margin-block-end:  1rem;
  border-bottom: 1px solid rgba(230, 230, 240, 1);

  ${customMediaQuery('tablet')} {
    border-radius: 16px;
    border-bottom: none;
    margin: 0 0 16px;
  }

  p {
    font-size: 0.75rem;
    font-weight: 500;
    line-height: 1rem;
    margin: 0;

    ${customMediaQuery('tablet')} {
      font-size: 0.875rem;
    }
  }
`;

const SUrlIcon = styled(UrlIcon)`
  margin-inline-end: 6px;
`;

const SAcceptButtonsBlock = styled(AcceptButtonsBlock)`
  & button {
    margin-bottom: 0;
  }
`;
